<template>
    <div style="width: 100%;height: 100%">
        <Echart width="100%" height="100%" :options="options"></Echart>
    </div>
</template>

<script>
import Echart from "@/common/echart";

let data1 = []
let data2 = []
let data3 = []
let data4 = []
let data5 = []
let data6 = []
for (let i = 0; i < 13; i++) {

	data1.push(Math.ceil(Math.random() * 300 + 200))
	data2.push(Math.ceil(Math.random() * 300 + 200))
	data3.push(Math.ceil(Math.random() * 300 + 200))
	data4.push(Math.ceil(Math.random() * 300 + 200))
	data5.push(Math.ceil(Math.random() * 300 + 200))
	data6.push(Math.ceil(Math.random() * 300 + 200))

}

export default {
	name: "pi-chart",
	components: {Echart},
	data() {
		return {
			options: {
				tooltip : {
					trigger: 'axis'
				},
				legend: {
					data: ['平台1', '平台3', '平台4', '平台5', '平台6', '平台7'],

				},
				grid: {
					x: 46,
					y:30,
					x2:32,
					y2:40,
					borderWidth: 0
				},
				calculable : true,
				xAxis : [
					{
						type : 'category',
						boundaryGap : false,
						data : ['2023-10','2023-11','2023-12','2023-1','2023-2','2023-3','2023-4','2023-5','2023-6','2023-7','2023-8','2023-9'],
						splitLine: {
							show: false
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#a4f7fb'
							}
						}
					}
				],
				yAxis : [
					{
						type : 'value',
						splitLine: {
							show: false
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#a4f7fb'
							}
						}

					}
				],
				series : [
					{
						name:'平台1',
						type:'line',
						stack: '人',
						data:data1,
						itemStyle: {
							normal: {
								color:"#45c0ff"
							}
						}
					},
					{
						name:'平台6',
						type:'line',
						stack: '人',
						data:data2,
						itemStyle: {
							normal: {
								color:"#0ad5ff"
							}
						}
					},
					{
						name:'平台5',
						type:'line',
						stack: '人',
						data:data3,
						itemStyle: {
							normal: {
								color:"#005ea1"
							}
						}
					},
					{
						name:'平台4',
						type:'line',
						stack: '人',
						data:data4,
						itemStyle: {
							normal: {
								color:"#ffcb89"
							}
						}
					},
					{
						name:'平台3',
						type:'line',
						stack: '人',
						data:data5,
						itemStyle: {
							normal: {
								color:"#2e7cff"
							}
						}
					},
					{
						name:'平台2',
						type:'line',
						stack: '人',
						data:data6,
						itemStyle: {
							normal: {
								color:"#1afffd"
							}
						}
					}
				]
			}
		}
	}
}
</script>

<style scoped>

</style>
