<template>
    <div style="width: 100%;height: 100%">
        <Echart width="100%" height="100%" :options="options"></Echart>
    </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
	name: "pi-chart",
	components:{Echart},
	data() {
		return {
			options: {
				tooltip : {
					trigger: 'item',
					formatter: "{b}: <br/>  {c} ({d}%)"
				},

				toolbox: {
					show : false,
					feature : {
						mark : {show: true},
						dataView : {show: true, readOnly: false},
						magicType : {
							show: true,
							type: ['pie', 'funnel']
						},
						restore : {show: true},
						saveAsImage : {show: true}
					}
				},
				calculable : true,
				series : [

					{
						name:'排名',
						type:'pie',
						color: ['#33b565', '#20cc98', '#20b9cf', '#2089cf', '#205bcf'],
						radius : [20, 70],
						center : ['50%', '50%'],
						roseType : 'area',
						data:[
							{value:70, name:'A国'},
							{value:90, name:'B国'},
							{value:110, name:'C国'},
							{value:150, name:'D国1'},
							{value:40, name:'E国'}

						]
					}
				]
			}
		}
	}
}
</script>

<style scoped>

</style>
