<template>
  <div id="scroll-board">
    <dv-scroll-board :config="config" />
  </div>
</template>

<script>
export default {
  name: 'ScrollBoard',
  data () {
    return {
      config: {
        header: ['时间', '国家', '金额', '订单编号'],
        data: [
          ['2023-09-22 19:25:00', 'xxx', '5', 'xxxxxxx'],
          ['2023-09-22 17:25:00', 'xxx', '13', 'xxxxxxx'],
          ['2023-09-22 16:25:00', 'xxx', '6', 'xxxxxxx'],
          ['2023-09-22 15:25:00', 'xxx', '2', 'xxxxxxx'],
          ['2023-09-22 14:25:00', 'xxxx', '1', 'xxxxxxx'],
          ['2023-09-22 13:25:00', 'xxxx', '3', 'xxxxxxx'],
          ['2023-09-22 12:25:00', 'xxxx', '4', 'xxxxxxx'],
          ['2023-09-22 11:25:00', 'xxxxxx', '2', 'xxxxxxx'],
          ['2023-09-22 10:25:00', 'xxxxxx', '5', 'xxxxxxx'],
          ['2023-09-22 09:25:00', 'xxxxx', '3', 'xxxxxxx']
        ],

        columnWidth: [180, 70, 70,110],
        align: ['center'],
        rowNum: 7,
        headerBGC: '#1981f611',
        headerHeight: 45,
        oddRowBGC: 'rgba(0, 144, 181, 0.1)',
        evenRowBGC: 'rgba(10, 129, 150, 0.1)'
      }
    }
  }
}
</script>

<style lang="less">
#scroll-board {
  width: 98%;
  box-sizing: border-box;
  margin-left: 0;
  height: 100%;
  overflow: hidden;
}
</style>
