<template>
    <div class="box">
        <div class="left" :style="{paddingTop:box_height}">
            <div style="flex: 1">
                <Box title="订单分类">
                    <OrderLeftTop></OrderLeftTop>
                </Box>

            </div>
            <div style="flex: 1">
                <Box title="平台订单统计">
                    <OrderLeftCenter></OrderLeftCenter>
                </Box>
            </div>
            <div style="flex: 1">
                <Box title="订单国家Top5">
                    <orderLeftBottom></orderLeftBottom>
                </Box>
            </div>

        </div>
        <div class="center">

            <div style="flex: 1">
                <Box title="签收分析">
                    <OrderCenterTop></OrderCenterTop>
                </Box>
            </div>
            <div style="flex: 1">
                <Box title="销售利润">
                    <OrderCenterCenter></OrderCenterCenter>
                </Box>
            </div>
            <div style="flex: 1">
                <Box title="平台订单数">
                    <OrderCenterBottom></OrderCenterBottom>
                </Box>
            </div>

        </div>
        <div class="right">
            <div style="flex: 1">

                <Box title="实时订单">
                    <FRightScrollBoard></FRightScrollBoard>
                </Box>

            </div>
            <div style="flex: 1">
                <Box title="拒签原因">
                    <OrderRightCenter></OrderRightCenter>
                </Box>
            </div>
            <div style="flex: 1;">

                <Box title="订单处理">
                    <OrderRightBottom></OrderRightBottom>
                </Box>
            </div>
        </div>
    </div>
</template>

<script>
import Box from "_c/box";
import FRightScrollBoard from "@/views/digital-factory/components/FRightScrollBoard";
import OrderLeftTop from "@/views/digital-factory/components/orderLeftTop";
import OrderLeftCenter from "@/views/digital-factory/components/orderLeftCenter";
import orderLeftBottom from "@/views/digital-factory/components/orderLeftBottom";
import OrderCenterTop from "@/views/digital-factory/components/OrderCenterTop";
import OrderCenterCenter from "@/views/digital-factory/components/OrderCenterCenter";
import OrderCenterBottom from "@/views/digital-factory/components/OrderCenterBottom";
import OrderRightCenter from "@/views/digital-factory/components/OrderRightCenter";
import OrderRightBottom from "@/views/digital-factory/components/OrderRightBottom";
export default {
	name: "dataA",
	components: {
		Box,FRightScrollBoard,orderLeftBottom,OrderCenterBottom,OrderRightCenter,
		OrderLeftTop,OrderLeftCenter,OrderCenterTop,OrderCenterCenter,OrderRightBottom
	},
	props: {
		box_height: {
			type: String,
			default() {
				return '110px';
			}
		}
	}
}
</script>

<style scoped lang="less">
.box {
  padding: 8px 0;
  & > div {
    flex: 2;
    display: flex;
    flex-direction: column;
    padding: 0;

    &.center {
      flex: 7;

    }

    &.right {
      flex: 3;
    }
  }

}
</style>
