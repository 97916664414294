<template>
    <div style="width: 100%;height: 100%">
        <Echart width="100%" height="100%" :options="options"></Echart>
    </div>
</template>

<script>
import Echart from "@/common/echart";

let data1 = []
let data2 = []
for (let i = 0; i < 32; i++) {
	data1.push(Math.ceil(Math.random() * 3000 + 2000))
	data2.push(Math.ceil(Math.random() * 50 + 10+i))
}

export default {
	name: "pi-chart",
	components: {Echart},
	data() {
		return {
			options: {
				tooltip: {
					trigger: 'axis'
				},
				grid: {
					x: 46,
					y: 30,
					x2: 30,
					y2: 20,
					borderWidth: 0
				},

				calculable: false,
				legend: {
					data: ['签收金额', '签收率'],

				},
				xAxis: [
					{
						type: 'category',
						data: [
							'2023-8-1', '2023-8-2', '2023-8-3', '2023-8-4', '2023-8-5', '2023-8-6', '2023-8-7', '2023-8-8', '2023-8-9', '2023-8-10', '2023-8-11', '2023-8-12',
							'2023-8-12',
							'2023-8-13',
							'2023-8-14',
							'2023-8-15',
							'2023-8-16',
							'2023-8-17',
							'2023-8-18',
							'2023-8-19',
							'2023-8-20',
							'2023-8-21',
							'2023-8-22',
							'2023-8-23',
							'2023-8-24',
							'2023-8-25',
							'2023-8-26',
							'2023-8-27',
							'2023-8-28',
							'2023-8-29',
							'2023-8-30',
							'2023-8-31',
						],
						splitLine: {
							show: false
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#a4f7fb',
								align: 'center'
							}
						}

					}
				],
				yAxis: [
					{
						type: 'value',

						name: '金额',
						axisLabel: {
							formatter: '{value} ',
							textStyle: {
								color: '#a4f7fb',
								align: 'right'
							}
						},
						splitLine: {
							show: false
						},
					},
					{
						type: 'value',
						name: '%',
						axisLabel: {
							formatter: '{value} ',
							textStyle: {
								color: '#faff0d',
								align: 'left'
							}
						},
						splitLine: {
							show: false
						},
					},

				],
				series: [

					{
						name: '签收金额',
						type: 'bar',
						data: data1,
						itemStyle: {
							normal: {
								color: "#0ad5ff"
							}
						}
					},

					{
						name: '签收率',
						type: 'line',
						yAxisIndex: 1,
						data: data2,
						itemStyle: {
							normal: {
								color: "#faff0d"
							}
						}
					}
				]
			}
		}
	}
}
</script>

<style scoped>

</style>
