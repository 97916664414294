<template>
    <div style="width: 100%;height: 100%">
        <Echart width="100%" height="100%" :options="options"></Echart>
    </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
	name: "pi-chart",
	components:{Echart},
	data() {
		return {
			options: {
				tooltip: {
					trigger: 'item',
					formatter: "{b}: <br/>{c} ({d}%)"
				},
				color: [ '#20b9cf', '#2089cf', '#205bcf'],
				legend: { //图例组件，颜色和名字
					x: '70%',
					y: 'center',
					orient: 'vertical',
					itemGap: 12, //图例每项之间的间隔
					itemWidth: 10,
					itemHeight: 10,
					icon: 'rect',
					data: ['分类一', '分类二', '分类三'],
					textStyle: {
						color: [],
						fontStyle: 'normal',
						fontFamily: '微软雅黑',
						fontSize: 12,
					}
				},
				series: [{
					name: '行业占比',
					type: 'pie',
					clockwise: false, //饼图的扇区是否是顺时针排布
					minAngle: 20, //最小的扇区角度（0 ~ 360）
					center: ['35%', '50%'], //饼图的中心（圆心）坐标
					radius: [40, 60], //饼图的半径
					//  avoidLabelOverlap: true, ////是否启用防止标签重叠
					itemStyle: { //图形样式
						normal: {
							borderColor: 'transparent',
							borderWidth: 2,
						},
					},
					label: { //标签的位置
						normal: {
							show: true,
							position: 'inside', //标签的位置
							formatter: "{d}%",
							textStyle: {
								color: '#fff',
							}
						},
						emphasis: {
							show: true,
							textStyle: {
								fontWeight: 'bold'
							}
						}
					},
					data: [
						{value: 42,name: '分类一'},
						{value: 23,name: '分类二'},
						{value: 70,name: '分类三'},

					]
				}, {
					name: '',
					type: 'pie',
					clockwise: false,
					silent: true,
					minAngle: 20, //最小的扇区角度（0 ~ 360）
					center: ['35%', '50%'], //饼图的中心（圆心）坐标
					radius: [0, 40], //饼图的半径
					itemStyle: { //图形样式
						normal: {
							borderColor: '#1e2239',
							borderWidth: 1.5,
							opacity: 0.21,
						}
					},
					label: { //标签的位置
						normal: {
							show: false,
						}
					},
					data: [
						{value: 42,name: '分类一'},
						{value: 23,name: '分类二'},
						{value: 70,name: '分类三'},

					]
				}]
			}
		}
	}
}
</script>

<style scoped>

</style>
