<template>
    <div style="width: 100%;height: 100%">
        <Echart width="100%" height="100%" :options="options"></Echart>
    </div>
</template>

<script>
import Echart from "@/common/echart";

let data1 = []
let data2 = []
for (let i = 0; i < 13; i++) {
	const a = Math.ceil(Math.random() * 300 + 200)
	data1.push(a)
	data2.push(a*(Math.random()*0.2+0.1))
}

export default {
	name: "pi-chart",
	components: {Echart},
	data() {
		return {
			options: {
				tooltip: {
					trigger: 'axis',
					axisPointer: {            // 坐标轴指示器，坐标轴触发有效
						type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
					}
				},
				legend: {
					data: ['销售额（万）', '毛利润（万）'],

				},
				grid: {
					x: 46,
					y: 30,
					x2: 32,
					y2: 40,
					borderWidth: 0
				},
				xAxis: [
					{
						type: 'category',
						data: ['2022-10', '2022-11', '2022-12', '2023-1', '2023-2', '2023-4', '2023-4',
							'2023-5',
							'2023-6',
							'2023-7',
							'2023-8',
							'2023-9',
						],
						splitLine: {
							show: false
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#a4f7fb',
								align: 'center'
							}
						}
					}
				],
				yAxis: [
					{
						type: 'value',
						splitLine: {
							show: false
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#a4f7fb'
							}
						}
					}
				],
				series: [
					{
						name: '销售额（万）',
						type: 'bar',
						data: data1,
						itemStyle: {
							normal: {
								color: "#2e7cff"
							}
						}
					},
					{
						name: '毛利润（万）',
						type: 'bar',
						stack: '广告',
						data: data2,
						itemStyle: {
							normal: {
								color: "#0ad5ff"
							}
						}
					}
				]
			}
		}
	}
}
</script>

<style scoped>

</style>
