<template>
    <div style="width: 100%;height: 100%">
        <Echart width="100%" height="100%" :options="options"></Echart>
    </div>
</template>

<script>
import Echart from "@/common/echart";

let data1 = []
let data2 = []
for (let i = 0; i < 13; i++) {
	const a = Math.ceil(Math.random() * 300 + 200)
	data1.push(a)
	data2.push(a*(Math.random()*0.2+0.1))
}

export default {
	name: "pi-chart",
	components: {Echart},
	data() {
		return {
			options: {
				tooltip : {
					trigger: 'item',
					formatter: "{a} <br/>{b} : {c} ({d}%)"
				},
				legend: {
					orient: 'vertical',
					x: 'right',
					data: ['已处理','未处理','超期未处理','其他'],
					textStyle:{
						color:"#e9ebee"
					}
				},
				series : [
					{
						name: '处理情况',
						type: 'pie',
						radius : '55%',
						center: ['50%', '60%'],
						data:[
							{value:335, name:'已处理',
								itemStyle:{
									normal:{
										color:"#2e7cff"
									}
								}},
							{value:310, name:'未处理',
								itemStyle:{
									normal:{
										color:"#ffcb89"
									}
								}},
							{value:234, name:'超期未处理',
								itemStyle:{
									normal:{
										color:"#2864ab"
									}
								}},
							{value:148, name:'其他',
								itemStyle:{
									normal:{
										color:"#e15828"
									}
								}}
						],
						itemStyle: {
							emphasis: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}
				]
			}
		}
	}
}
</script>

<style scoped>

</style>
