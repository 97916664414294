<template>
    <div style="width: 100%;height: 100%">
        <Echart width="100%" height="100%" :options="options"></Echart>
    </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
	name: "pi-chart",
	components:{Echart},
	data() {
		return {
			options: {
				tooltip: {
					trigger: 'item',
					formatter: "{b}  <br/>{c}单"
				},
				legend: {
					x: 'center',
					y: '2%',
					data: ['平台一', '平台二', '平台三', '平台四', '平台五', '平台六', '平台七'],
					icon: 'circle',
					textStyle: {
						color: '#fff',
					}
				},
				calculable: true,
				series: [{
					name: '平台',
					type: 'pie',
					//起始角度，支持范围[0, 360]
					startAngle: 0,
					//饼图的半径，数组的第一项是内半径，第二项是外半径
					radius: [31, 60],
					//支持设置成百分比，设置成百分比时第一项是相对于容器宽度，第二项是相对于容器高度
					center: ['50%', '60%'],
					//是否展示成南丁格尔图，通过半径区分数据大小。可选择两种模式：
					// 'radius' 面积展现数据的百分比，半径展现数据的大小。
					//  'area' 所有扇区面积相同，仅通过半径展现数据大小
					roseType: 'area',
					//是否启用防止标签重叠策略，默认开启，圆环图这个例子中需要强制所有标签放在中心位置，可以将该值设为 false。
					avoidLabelOverlap: false,
					label: {
						normal: {
							show: true,
							formatter: '{c}单'
						},
						emphasis: {
							show: true
						}
					},
					labelLine: {
						normal: {
							show: true,
							length2: 1,
						},
						emphasis: {
							show: true
						}
					},
					data: [{
						value: 600,
						name: '平台一',
						itemStyle: {
							normal: {
								color: '#33b565'
							}
						}
					},
						{
							value: 1100,
							name: '平台二',
							itemStyle: {
								normal: {
									color: '#20cc98'
								}
							}
						},
						{
							value: 1200,
							name: '平台三',
							itemStyle: {
								normal: {
									color: '#208fcf'
								}
							}
						},
						{
							value: 1300,
							name: '平台四',
							itemStyle: {
								normal: {
									color: '#ff5bff'
								}
							}
						},


						{
							value: 990,
							name: "平台五",

						},
						{
							value: 786,
							name: "平台六",

						},
						{
							value: 238,
							name: "平台七",

						},
						{
							value: 0,
							name: "",
							label: {
								show: false
							},
							labelLine: {
								show: false
							}
						},
						{
							value: 0,
							name: "",
							label: {
								show: false
							},
							labelLine: {
								show: false
							}
						}
					]
				}]
			}
		}
	}
}
</script>

<style scoped>

</style>
